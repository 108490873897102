import React from "react"
import Case from '../../../sections/Case';
import {useEnCaseData} from '../../../shared/Hooks/Case/useEnCaseData';
import {useEnHeaderData} from '../../../shared/Hooks/Headers/useEnHeaderData';

const CaseShell = () => {
  const data = {
    seo: {
      title: 'Shell deli2go – Snack bars at petrol stations.',
    },
    cases: useEnCaseData().shell,
    header: useEnHeaderData().shell,
  };

  return (
    <Case data={data}/>
  );
}

export default CaseShell
